<template>
  <div class="information-container">
    <v-container>
      <v-row>
        <v-col cols="12" sm="11" offset-sm="1" md="10" offset-md="1" lg="10" offset-lg="1" xl="10" offset-xl="1">
          <v-row v-if="isLandingPage()">
            <v-col class="justify-center">
              <!--<v-img style="width: 200px;display: inline-block;" src="https://ebikecup.com/images/E-Bike_for_Future_Logo.png"/>-->
            </v-col>
          </v-row>
          <v-row class="text-image-container">
            <v-col cols="12" sm="12" md="8" lg="8" xl="7">
              <h1>{{$t('home.about.information.title')}}</h1>
              <ul>
                <li>{{$t('home.about.information.ul1')}} <br/> {{$t('home.about.information.ul2')}}</li>
              </ul>
              <h1>{{$t('home.about.information.title2')}}</h1>
              <ul>
                <li>{{$t('home.about.information.ul3')}}</li>
              </ul>
              <div class="hint" style="max-width: 400px">
                {{$t('home.about.information.hint')}}
              </div>
              <h1>{{$t('home.about.information.title3')}}</h1>
              <ul>
                <li>{{$t('home.about.information.ul5')}}</li>
                <li>{{$t('home.about.information.ul6')}}</li>
              </ul>
              <v-row id="safe">
                <v-col cols="12" class="information-safe-container">
                  <h1 class="mb-0 mt-6">{{$t('home.about.information.safe.title')}}</h1>
                  <h1 class="mb-4 mt-0">{{$t('home.about.information.safe.title2')}}</h1>
                  <h2>{{$t('home.about.information.safe.subtitle')}}</h2>
                  <p>{{$t('home.about.information.safe.paragraph1')}}</p>
                  <ul>
                    <li>
                      {{$t('home.about.information.safe.paragraph2')}}
                      <ul>
                        <li>
                          {{$t('home.about.information.safe.paragraph3')}}
                        </li>
                        <li>
                          {{$t('home.about.information.safe.paragraph4')}}
                        </li>
                      </ul>
                    </li>
                    <li>
                      {{$t('home.about.information.safe.paragraph5')}} <br/>
                      <strong> {{$t('home.about.information.safe.paragraph6')}}</strong>
                    </li>
                    <li>
                      {{$t('home.about.information.safe.paragraph7')}}
                      <ul>
                        <li>
                          {{$t('home.about.information.safe.paragraph8')}}
                        </li>
                        <li>
                          {{$t('home.about.information.safe.paragraph9')}}
                        </li>
                      </ul>
                    </li>
                    <li>
                      {{$t('home.about.information.safe.paragraph10')}}
                    </li>
                  </ul>
                  <br/>
                  {{$t('home.about.information.safe.paragraph11')}}
                  <h2 class="mt-4 mb-1">{{$t('home.about.information.safe.subtitle2')}}</h2>
                  {{$t('home.about.information.safe.paragraph12')}}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'home-information',
    data() {
      return {
      };
    },
    methods: {
      isLandingPage() {
        return this.$route.name === 'home';
      }
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";

  .information-container {
    min-height: 600px;
    background-image: url("https://ebikecup.com/images/information/EBCUP_Backgroundimage_Landkarte_02-2022.jpg");
    background-position: 0 40%;
    background-size: contain;
    padding-bottom: 20px;
    padding-top: 10px;
    margin-top: 10px;
    @include MQ(XXL) {
      min-height: 1000px;
    }
    @include MQ(XL) {
      padding-bottom: 20px;
      padding-top: 10px;
    }
    @include MQ(L) {
      padding-bottom: 20px;
      padding-top: 10px;
    }
    @include MQ(S) {
      background-position: center;
      background-size: cover;
    }
    @include MQ(XS) {
      background-position: center;
      background-size: cover;
    }
  }

  .information-container {
    .motto {
      font-weight: 400;
      font-style: italic;
      color: $primary-text;
      max-width: 500px;
      display: inline-block;
    }
    .text-image-container {
      @include MQ(XS) {
        padding-left: 10px;
      }
      h1 {
        margin-top: 15px;
        text-transform: uppercase;
      }
      .information-safe-container {
        text-align: left;
        h2 {
          color: $primary;
          font-style: italic;
          text-align: left;
        }
      }
    }
  }

</style>

<template>
  <v-container>
    <v-main>
      <v-row class="pt-10 pb-2">
        <v-col cols="12" id="news">
          <h1 style="font-weight: 400;">News</h1>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1">
          <ul class="juicer-feed" data-feed-id="ebikewm2019" data-per="5"
              :data-columns="getColumns()"/>
        </v-col>
      </v-row>
    </v-main>
  </v-container>
</template>

<script>
  export default {
    name: 'news',
    methods: {
      getColumns() {
        if (this.$vuetify.breakpoint.xsOnly) {
          return 1;
        }
        return this.$vuetify.breakpoint.mdAndDown ? 2 : 3;
      },
    },
  }
</script>

<style lang="scss">
  @import "../../assets/scss/variables";

  .juicer-feed {
    padding-left: 0;
    .referral {
      display: none !important;
    }
    .juicer-button {
      border-color: $primary;
      color: $primary;
      &:hover {
        background-color: $primary;
        color: white;
      }
    }
    a {
      &:hover {
        color: $primary;
      }
    }
  }
</style>

<template>
  <div class="home-background-image-shop" id="shop">
    <div class="shop-container">
      <h1>{{$t('home.shop.official')}}</h1>
      <h1>{{$t('home.shop.title')}}</h1>
      <v-btn color="white" @click="goToShop()">
        {{$t('home.shop.toShop')}}
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'shop',
    methods: {
      goToShop() {
        window.open('https://ebikeworldfederation.com/shop?origin=EBIKECUP', '_blank');
      }
    }
  }
</script>

<style lang="scss">
  @import "../assets/scss/variables";
  @import "../assets/scss/layout";
  .home-background-image-shop {
    background-image: url("https://ebikeworldfederation.com/images/shop/EBWF_Wirsindebike_Shop_Teaserbild_01.jpg");
    background-position: center;
    background-size: cover;
    height: 600px;
    position: relative;
    @include MQ(M) {
      height: 550px;
    }
    @include MQ(S) {
      height: 400px;
    }
    @include MQ(XS) {
      height: 350px;
    }
    .shop-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      h1 {
        color: white;
        font-weight: 600;
        font-style: italic;
        &.subtitle {
          color: $secondary;
        }
      }
      .v-btn {
        margin-top: 50px;
        text-transform: none;
        border-radius: 15px;
      }
    }
  }
</style>
